<template>
  <div class="world quote-select-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Back to overview') }}</button>
        <div class="flex">
          <div>
            <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}</h1>
            <div class="intro">
              <p>{{ $t('Wij geven je een accurate offerte in slechts enkele stappen!') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h2>{{ $t('Wat wil je configureren?') }}</h2>

      <div class="select-buttons shutters">
        <button :class="{ 'active': selectedType === 'single' }" class="select-button" @click="selectType('single')">
          <img v-if="selectedType === 'single'" class="icon-check" alt="" src="@/assets/img/check_solid.svg" width="23">

          <div class="icon">
            <img alt="" src="@/assets/img/house.svg">
            <div class="type">
              <img alt="" src="@/assets/img/shutters-single.svg">
            </div>
          </div>
          {{ $t('1 rolluik') }}
        </button>

        <button :class="{ 'active': selectedType === 'multiple' }" class="select-button" @click="selectType('multiple')">
          <img v-if="selectedType === 'multiple'" class="icon-check" alt="" src="@/assets/img/check_solid.svg" width="23">
          <div class="icon">
            <img alt="" src="@/assets/img/house.svg">
            <div class="type">
              <img alt="" src="@/assets/img/shutters-multiple.svg">
            </div>
          </div>
          {{ $t('Meerdere rolluiken') }}
        </button>

        <button class="btn btn-confirm" @click="confirmType">
          {{ $t('Start') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import 'vue-select/dist/vue-select.css'
import Calculators from '@/calculators'

export default {
  name: 'ShutterSelect',
  mixins: [
    Calculators
  ],
  data () {
    return {
      loading: true,
      quoteNumber: null,
      selectedType: null
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
    this.loading = false
  },
  methods: {
    backToOverview () {
      this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    selectType (type) {
      this.selectedType = type
    },
    async confirmType () {
      if (this.selectedType === 'single') {
        await this.addShutter()
      } else if (this.selectedType === 'multiple') {
        await this.$router.push({
          name: 'ww.quotes.calc.shutter_bulk',
          params: { quoteId: this.$route.params.quoteId }
        })
      }
    },
    async addShutter () {
      await ApiService.createShutter({
        quote_id: this.$route.params.quoteId
      }).then(async res => {
        if (res.status === 200) {
          await this.$router.push({
            name: 'ww.quotes.calc.shutter',
            params: { quoteId: this.$route.params.quoteId, id: res.data.id }
          })
        }
      })
    }
  }
}
</script>
